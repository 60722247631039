import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { useState, useRef } from 'react';
import { db, storage } from '../utils/fb';
import { convertToSeconds } from '../utils/handleMoment';
import { Toastr, ToastrRef } from '@paljs/ui/Toastr';

const AddLesson = ({ course, toggleModal }) => {
  const [lesson, setLesson] = useState({
    lessonTitle: '',
    lessonFile: '',
    lessonDuration: '',
    lessonHrs: '00',
    lessonMins: '00',
    lessonImg: '',
  });
  const { lessonTitle, lessonFile, lessonDuration, lessonHrs, lessonMins, lessonImg } = lesson;
  const handleLessonChange = (e: any) => setLesson({ ...lesson, [e.target.name]: e.target.value });

  const [titleErr, setTitleErr] = useState('');
  const [fileErr, setFileErr] = useState('');
  const [hrsErr, setHrsErr] = useState('');
  const [minsErr, setMinsErr] = useState('');
  const [imgErr, setImgErr] = useState('');

  const createLesson = async (course: any) => {
    if (!lessonTitle) {
      setTitleErr('Lesson Name is required');
    }
    if (!lessonFile) {
      setFileErr('Lesson File is required');
    }
    if (!lessonHrs) {
      setHrsErr('Please provide hours or add "00"');
    }
    if (!lessonMins) {
      setMinsErr('Please provide minutes or add "00"');
    }
    if (!lessonImg) {
      setImgErr('Image is required');
    }
    if (!lessonTitle || !lessonFile || !lessonHrs || !lessonMins || !lessonImg) {
      return;
    }
    try {
      // upload lesson file
      let lessonFileUrl;
      let lessonFilePath;
      if (lessonFile) {
        const imgRef = ref(storage, `lessons/files/${new Date().getTime()} - ${lessonFile.name}`);
        const snap = await uploadBytes(imgRef, lessonFile);
        const fileUrl = await getDownloadURL(ref(storage, snap.ref.fullPath));
        lessonFileUrl = fileUrl;
        lessonFilePath = snap.ref.fullPath;
      }
      // upload lesson cover image
      let lessonCoverUrl;
      let lessonCoverPath;
      if (lessonImg) {
        const imgRef = ref(storage, `lessons/covers/${new Date().getTime()} - ${lessonImg.name}`);
        const snap = await uploadBytes(imgRef, lessonImg);
        const fileUrl = await getDownloadURL(ref(storage, snap.ref.fullPath));
        lessonCoverUrl = fileUrl;
        lessonCoverPath = snap.ref.fullPath;
      }

      // add into db
      await updateDoc(doc(db, 'courses', course.id), {
        lessons: course.lessons.concat({
          lessonTitle,
          lessonFileUrl,
          lessonFilePath,
          lessonDuration: convertToSeconds(lessonHrs + ':' + lessonMins),
          lessonCoverUrl,
          lessonCoverPath,
          createdAt: Timestamp.fromDate(new Date()),
          timeSpent: 0,
        }),
        status: { label: 'Publish', value: 'Publish' },
        // duration: [Math.floor(sum.asHours()), sum.minutes()].join(':'),
        duration: parseInt(course.duration) + convertToSeconds(lessonHrs + ':' + lessonMins),
      });
      setLesson({
        lessonTitle: '',
        lessonFile: '',
        lessonDuration: '',
        lessonHrs: '00',
        lessonMins: '00',
        lessonImg: '',
      });
      showToastr('Success', 'Lesson successfully created');
      // toggleModal();
    } catch (err) {
      console.log(err.message);
    }
  };

  const toastrRef = useRef<ToastrRef>(null);

  const showToastr = (title, message) => {
    toastrRef.current?.add(message, title);
  };

  return (
    <div className="newLessonWrapper">
      <Toastr ref={toastrRef} />
      <h3>Create New Lesson</h3>
      <form className="modalEditWrap">
        <div>
          <label>Name:</label>
          <input
            type="text"
            id="exampleInputEmail1"
            name="lessonTitle"
            value={lessonTitle}
            onChange={handleLessonChange}
          />
        </div>
        <div className="fileUploadWrap">
          <label>File:</label>
          <div className="fileUploadWrapper">
            <input
              type="file"
              onChange={(e) => setLesson({ ...lesson, lessonFile: e.target.files[0] })}
              accept=".ppt, .pptx"
            ></input>
            <span id="absoluteHelperText">Only upload valid powerpoint files. Max file size of 10mb.</span>
            {/* <span>lesson-1-june.pdf</span> */}
          </div>
        </div>
        <div className="timeDurationTimeSelector">
          <label>Time Duration:</label>
          <input
            type="number"
            id="exampleInputEmail1"
            placeholder="Hours"
            name="lessonHrs"
            value={lessonHrs}
            onChange={handleLessonChange}
            className="firstInputLesson"
          />
          <input
            type="number"
            id="exampleInputEmail1"
            placeholder="Minutes"
            name="lessonMins"
            value={lessonMins}
            onChange={handleLessonChange}
          />
        </div>
        <div className="fileUploadWrap">
          <label>Cover Image:</label>
          <div className="fileUploadWrapper">
            <input type="file" onChange={(e) => setLesson({ ...lesson, lessonImg: e.target.files[0] })}></input>
            {/* <span>sosa-air-brake-course.png</span> */}
          </div>
        </div>
        <div className="courseLessonTable"></div>
        <button id="updateUser" onClick={() => createLesson(course)} type="button">
          Create Lesson
        </button>
        <hr className="newLB" />
      </form>
    </div>
  );
};

export default AddLesson;
