import Select from '@paljs/ui/Select';
import { Radio } from '@paljs/ui/Radio';
import { Card, CardBody } from '@paljs/ui/Card';
import { Checkbox } from '@paljs/ui/Checkbox';
import { InputGroup } from '@paljs/ui/Input';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useState } from 'react';
import styled from 'styled-components';
import SEO from '../SEO';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc, Timestamp } from 'firebase/firestore';
import { navigate } from 'gatsby';
import { auth, db } from '../../utils/fb';

const options = [
  { value: 'Instructor', label: 'Instructor' },
  { value: 'Admin', label: 'Admin' },
];

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const InputPage = () => {
  const [checkbox, setCheckbox] = useState({
    1: false,
    2: false,
    3: false,
  });

  const onChangeCheckbox = (value: boolean, name: number) => {
    setCheckbox({ ...checkbox, [name]: value });
  };
  const onChangeRadio = (_value: any) => {
    // value will be item value
  };

  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',
    company: '',
    position: '',
    role: '',
    notes: '',
  });
  const { name, email, password, company, position, role, notes } = values;
  const [nameErr, setNameErr] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [passwordErr, setPasswordErr] = useState('');
  const [companyErr, setCompanyErr] = useState('');
  const [positionErr, setPositionErr] = useState('');
  const [roleErr, setRoleErr] = useState('');
  const [notesErr, setNotesErr] = useState('');
  const [err, setErr] = useState('');

  const handleChange = (e: any) => setValues({ ...values, [e.target.name]: e.target.value });

  const onChangeRole = (selectedOption: any) => {
    setValues({ ...values, role: selectedOption });
  };

  function validateEmail(email: any) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const handleErr = (val: string, setValErr: any, message: string) => {
    if (!val) {
      setValErr(message);
      return;
    }
  };

  const handleClick = async () => {
    setNameErr('');
    setEmailErr('');
    setPasswordErr('');
    setCompanyErr('');
    setPositionErr('');
    setRoleErr('');
    setNotesErr('');
    handleErr(name, setNameErr, 'Name is required');
    handleErr(email, setEmailErr, 'Email is required');
    handleErr(password, setPasswordErr, 'Password is required');
    handleErr(company, setCompanyErr, 'Company is required');
    handleErr(position, setPositionErr, 'Position is required');
    handleErr(notes, setNotesErr, 'Notes is required');
    if (!validateEmail(email)) {
      setEmailErr('Please provide a valid email');
      return;
    }
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);
      await setDoc(doc(db, 'users', result.user.uid), {
        uid: result.user.uid,
        name,
        email,
        company,
        position,
        role,
        notes,
        isActive: true,
        createdAt: Timestamp.fromDate(new Date()),
      });
      setValues({ name: '', email: '', password: '', company: '', position: '', role: '', notes: '' });
      navigate('/users/');
    } catch (err) {
      setErr(err.message);
    }
  };

  return (
    <>
      <SEO title="Input" />
      <Row className="lsUserForm">
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Create New User</header>
            <CardBody>
              <Input fullWidth size="Medium">
                <input type="text" placeholder="Full Name" name="name" value={name} onChange={handleChange} />
              </Input>
              {nameErr && <p>{nameErr}</p>}
              <Input fullWidth size="Medium">
                <input type="text" placeholder="Email address" name="email" value={email} onChange={handleChange} />
              </Input>
              {emailErr && <p>{emailErr}</p>}
              <Input fullWidth size="Medium">
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                />
              </Input>
              {passwordErr && <p>{passwordErr}</p>}
              <Input fullWidth size="Medium">
                <input type="text" placeholder="Company" name="company" value={company} onChange={handleChange} />
              </Input>
              {companyErr && <p>{companyErr}</p>}
              <Input fullWidth size="Medium">
                <input type="text" placeholder="Position" name="position" value={position} onChange={handleChange} />
              </Input>
              {positionErr && <p>{positionErr}</p>}
              <Select fullWidth placeholder="Select User Type" options={options} onChange={onChangeRole} />
              {roleErr && <p>{roleErr}</p>}
              <Input fullWidth shape="Round">
                <textarea rows={5} placeholder="Custom Notes:" name="notes" value={notes} onChange={handleChange} />
              </Input>
              {notesErr && <p>{notesErr}</p>}
              <button id="createUserBtn" onClick={handleClick}>
                Create User
              </button>
              {err && <p>{err}</p>}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default InputPage;
