import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SEO from '../SEO';
import { Timestamp, addDoc, collection } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
// import { navigate } from 'gatsby';
import { db, storage } from '../../utils/fb';
import { isInstructor } from '../../utils/isInstructor';
import { Toastr, ToastrRef } from '@paljs/ui/Toastr';
import slugify from 'slugify';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const InputPage = () => {
  useEffect(() => {
    isInstructor();
  }, []);

  const [values, setValues] = useState({
    title: '',
    creator: '',
    img: '',
    notes: '',
  });
  const { title, creator, img, notes } = values;

  const [titleErr, setTitleErr] = useState('');
  const [creatorErr, setCreatorErr] = useState('');
  const [imgErr, setImgErr] = useState('');
  const [notesErr, setNotesErr] = useState('');

  const handleChange = (e: any) => setValues({ ...values, [e.target.name]: e.target.value });

  const handleClick = async () => {
    setTitleErr('');
    setCreatorErr('');
    setImgErr('');
    setNotesErr('');
    if (!title) {
      setTitleErr('Course Name is required');
    }
    if (!creator) {
      setCreatorErr('Course Creator Name is required');
    }
    if (!img) {
      setImgErr('Cover Image is required');
    }
    if (!notes) {
      setNotesErr('Course Notes is required');
    }
    if (!title || !creator || !img || !notes) {
      return;
    }

    try {
      const imgRef = ref(storage, `courses/${new Date().getTime()} - ${img.name}`);
      const snap = await uploadBytes(imgRef, img);
      const fileUrl = await getDownloadURL(ref(storage, snap.ref.fullPath));

      await addDoc(collection(db, 'courses'), {
        title,
        slug: slugify(title, {
          lower: true,
          trim: true,
        }),
        creator,
        imgUrl: fileUrl,
        imgPath: snap.ref.fullPath,
        notes,
        createdAt: Timestamp.fromDate(new Date()),
        lessons: [],
        duration: 0,
        status: { label: 'Draft', value: 'Draft' },
      });
      setValues({ title: '', creator: '', img: '', notes: '' });
      showToastr('Success', 'Course successfully created');
      // navigate('/app/courses');
    } catch (err) {
      console.log(err.message);
    }
  };

  const toastrRef = useRef<ToastrRef>(null);

  const showToastr = (title, message) => {
    toastrRef.current?.add(message, title);
  };

  return (
    <>
      <Toastr ref={toastrRef} />
      <SEO title="Input" />
      <Row className="lsUserForm">
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <h2 style={{ textAlign: 'center' }}>Create New Course</h2>
          <Card>
            <header>
              You can edit course contents in future.{' '}
              <small>
                {' '}
                <small>
                  <em> &nbsp;Also This is a new course, not lesson.</em>
                </small>
              </small>
            </header>
            <CardBody>
              <Input fullWidth size="Medium">
                <input type="text" placeholder="Course Name" name="title" value={title} onChange={handleChange} />
              </Input>
              {titleErr && <p>{titleErr}</p>}
              <Input fullWidth size="Medium">
                <input
                  type="text"
                  placeholder="Course Creator Name"
                  name="creator"
                  value={creator}
                  onChange={handleChange}
                />
              </Input>
              {creatorErr && <p>{creatorErr}</p>}

              <label className="courseCoverImgLabel">Course Cover Image</label>
              <Input fullWidth size="Medium">
                <input type="file" onChange={(e) => setValues({ ...values, img: e.target.files[0] })} />
              </Input>
              {imgErr && <p>{imgErr}</p>}

              <Input fullWidth shape="Medium">
                <textarea rows={5} placeholder="Course Notes:" name="notes" value={notes} onChange={handleChange} />
              </Input>
              {notesErr && <p>{notesErr}</p>}
              <button id="createUserBtn" onClick={handleClick}>
                Create Course
              </button>
              {/* {err && <p>{err}</p>} */}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default InputPage;
