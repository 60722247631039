import { Accordion, AccordionRefObject } from '@paljs/ui/Accordion';
import Row from '@paljs/ui/Row';
import Col from '@paljs/ui/Col';
import React, { useRef, useState, useEffect } from 'react';
import SEO from '../SEO';
import { collection, query, onSnapshot } from 'firebase/firestore';
import User from '../User';
import { db } from '../../utils/fb';
import { isInstructor } from '../../utils/isInstructor';

const Accordions = () => {
  const accordionRef = useRef<AccordionRefObject>(null);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    isInstructor();
    const q = query(collection(db, 'users'));
    const unsub = onSnapshot(q, (querySnapshot) => {
      let users: any = [];
      querySnapshot.forEach((doc) => {
        users.push(doc.data());
      });
      setUsers(users);
    });
    return () => unsub();
  }, []);




  return (
    <>
      <SEO title="Accordions" />
       <h2 style={{ textAlign: 'center' }}>Manage Users</h2>
      <Row className="userTabs">
        <Col breakPoint={{ xs: 12, lg: 12 }}>
          <Accordion multi ref={accordionRef}>
            {users.map((user: any, i: any) => (
              <User key={user.uid} user={user} i={i} />
            ))}
          </Accordion>
        </Col>
      </Row>
    </>
  );
};

export default Accordions;
