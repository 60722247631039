import React, { useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import { Router } from '@reach/router';
import { AuthContext } from '../context/auth';
import Instructor from '../components/instructor';
import PendingUsers from '../components/users/pending-users';
import CreateUser from '../components/users/create-user';
import Users from '../components/users';
import InstructorCourses from '../components/instructor/course';
import InstructorOpenCourses from '../components/instructor/courses/open-courses';
import InstructorPastCourses from '../components/instructor/courses/past-courses';
import Courses from '../components/courses';
import CreateCourse from '../components/courses/create-course';
import EditCourse from '../components/courses/edit-course';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../utils/fb';

const App = () => {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!user) {
      navigate('/auth/login');
    }
  }, [user]);

  useEffect(() => {
    if (window.location.href.indexOf('app/users') > -1 || window.location.href.indexOf('app/courses')) {
      // all users
      let users = document.querySelector('.sidebar-menu.mainMenu ul li:nth-child(2) a span');
      if (users) {
        onSnapshot(collection(db, 'users'), (querySnapshot) =>
          querySnapshot.docs.length > 0 ? (users.innerHTML += `<small>${querySnapshot.docs.length}</small>`) : null,
        );
      }

      // pending users
      let pending = document.querySelector('.sidebar-menu.mainMenu ul li:nth-child(3) a span');
      if (pending) {
        onSnapshot(collection(db, 'requests'), (querySnapshot) =>
          querySnapshot.docs.length > 0 ? (pending.innerHTML += `<small>${querySnapshot.docs.length}</small>`) : null,
        );
      }

      // manage courses
      let courses = document.querySelector('.sidebar-menu.mainMenu ul li:nth-child(6) a span');
      if (courses) {
        onSnapshot(collection(db, 'courses'), (querySnapshot) =>
          querySnapshot.docs.length > 0 ? (courses.innerHTML += `<small>${querySnapshot.docs.length}</small>`) : null,
        );
      }
    }
  }, []);

  return (
    <>
      <Router basepath="/app">
        <PendingUsers path="/users/pending-users" />
        <Users path="/users" />
        <Instructor path="/instructor" />
        <InstructorCourses path="/instructor/course/:slug" />
        <InstructorOpenCourses path="/instructor/courses/open-courses" />
        <InstructorPastCourses path="/instructor/courses/past-courses" />
        <Courses path="/courses" />
        <CreateCourse path="/courses/create-course" />
        <CreateUser path="/users/create-user" />
        <EditCourse path="/courses/edit-course" />
      </Router>
    </>
  );
};
export default App;
