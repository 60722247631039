import Row from '@paljs/ui/Row';
import Col from '@paljs/ui/Col';
import React, { useState, useEffect } from 'react';
import SEO from '../../SEO';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../../utils/fb';
import Lesson from '../../Lesson';

const Accordions = (props) => {
  const [course, setCourse] = useState();
  useEffect(() => {
    // Update the document title using the browser API
    document.querySelector('body')?.classList.add('instructor');

    const q = query(collection(db, 'courses'), where('slug', '==', props.slug));
    const unsub = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => setCourse({ ...doc.data(), id: doc.id }));
    });
    return () => unsub();
  }, []);

  return course ? (
    <>
      <SEO title="Accordions" />
      <Row className="userTabs">
        <Col breakPoint={{ xs: 12, lg: 12 }}>
          <h2 style={{ textAlign: 'center' }}>{course.title}</h2>
          <div className="courseCardWrapperLessons courseCardWrapper">
            {course.lessons.map((lesson, i) => (
              <Lesson key={i} lesson={lesson} i={i} course={course} />
            ))}
          </div>
        </Col>
      </Row>
    </>
  ) : null;
};

export default Accordions;
