import React, { useState, useRef } from 'react';
import { AccordionItem } from '@paljs/ui/Accordion';
import Modal from 'react-modal';
import { deleteDoc, doc, Timestamp, updateDoc } from 'firebase/firestore';
import { db, storage } from '../utils/fb';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import AddLesson from './AddLesson';
import UpdateLesson from './UpdateLesson';
import { convertFromSeconds } from '../utils/handleMoment';
import { Toastr, ToastrRef } from '@paljs/ui/Toastr';
import Select from '@paljs/ui/Select';
import slugify from 'slugify';

// const linkToPPTFile = 'https://scholar.harvard.edu/files/torman_personal/files/samplepptx.pptx';

const options = [
  { value: 'Draft', label: 'Draft' },
  { value: 'Publish', label: 'Publish' },
];

const Course = (props: any) => {
  const { course, i } = props;
  const [idxOne, setIdxOne] = useState(-1);
  const [idxTwo, setIdxTwo] = useState(-1);
  const [idxThree, setIdxThree] = useState(-1);
  const [addLesson, setAddLesson] = useState(false);

  const [values, setValues] = useState({
    title: course.title,
    creator: course.creator,
    img: '',
    notes: course.notes,
    duration: course.duration,
    status: course.status,
    lessons: course.lessons?.length,
  });
  const { title, creator, img, notes, duration, status, lessons } = values;

  const handleChange = (e: any) => setValues({ ...values, [e.target.name]: e.target.value });

  const onChangeStatus = (selectedOption: any) => {
    setValues({ ...values, status: selectedOption });
  };

  function toggleModal() {
    idxOne !== i ? setIdxOne(i) : setIdxOne(-1);
  }

  function toggleModalTwo() {
    idxTwo !== i ? setIdxTwo(i) : setIdxTwo(-1);
  }

  function toggleModalThree() {
    idxThree !== i ? setIdxThree(i) : setIdxThree(-1);
  }

  const updateCourse = async (course: any) => {
    let imgUrl;
    let imgPath;
    if (img) {
      if (course.imgUrl) {
        await deleteObject(ref(storage, course.imgPath));
      }
      const imgRef = ref(storage, `courses/${new Date().getTime()} - ${img.name}`);
      const snap = await uploadBytes(imgRef, img);
      const fileUrl = await getDownloadURL(ref(storage, snap.ref.fullPath));
      imgUrl = fileUrl;
      imgPath = snap.ref.fullPath;
    }

    await updateDoc(doc(db, 'courses', course.id), {
      title,
      slug: slugify(title, { lower: true, trim: true }),
      creator,
      imgUrl: imgUrl || course.imgUrl,
      imgPath: imgPath || course.imgPath,
      updatedAt: Timestamp.fromDate(new Date()),
      notes,
      status,
    });
    // toggleModal();
    showToastr('Success', 'Course successfully updated');
  };

  const deleteCourse = async (course: any) => {
    if (course.imgUrl) {
      await deleteObject(ref(storage, course.imgPath));
    }
    await deleteDoc(doc(db, 'courses', course.id));
    toggleModalTwo();
  };
  const [previewIdx, setPreviewIdx] = useState(0);

  const toastrRef = useRef<ToastrRef>(null);

  const showToastr = (title, message) => {
    toastrRef.current?.add(message, title);
  };

  return (
    <AccordionItem
      uniqueKey={course.id}
      title={
        <div className="userNameWrap">
          <Toastr ref={toastrRef} />
          <h6>{course.title}</h6>
          <p>Last Edited: {course.createdAt.toDate().toGMTString()}</p>
        </div>
      }
    >
      <h6>Course Details: </h6>
      <p>
        <strong>Name: </strong>
        {course.title}
        <br />
        <strong>Amount of Lessons:</strong> {course.lessons?.length}
        <br />
        <strong>Total Time: </strong> {convertFromSeconds(course.duration)} <br />
        {/* <strong>Total Time Duration: </strong> 5 hours 28 minutes <br /> */}
        <strong>Last Edited: </strong>{' '}
        {course.updatedAt ? course.updatedAt.toDate().toGMTString() : course.createdAt.toDate().toGMTString()} <br />
        <strong>Course Creator: </strong> {course.creator} <br />
        <strong>Status: </strong> <green>{course.status.value === 'Draft' ? 'Draft' : 'Published'}</green> <br />
      </p>
      <h6> Cover Image: </h6>
      <img src={course.imgUrl} className="courseCoverImg" />
      <p>
        <strong>Course Notes:</strong> {course.notes}
      </p>
      <div className="userButtonWrapper">
        <button id="editUserBtn" onClick={toggleModal}>
          Edit Course
        </button>
        <button id="deleteUserBtn" onClick={toggleModalTwo}>
          Delete Course
        </button>
        <button id="cancelReset" onClick={toggleModalThree}>
          Preview Course
        </button>
        {/* // these will all be unique PPTs in the modal thats being called on  */}
      </div>

      {/* Edit Lesson Modal  */}
      <Modal isOpen={idxOne === i} onRequestClose={toggleModal} contentLabel="Modal">
        <h3>Edit {course.title}</h3>
        <form className="modalEditWrap">
          <div>
            <label>Course Name:</label>
            <input type="text" id="exampleInputEmail1" name="title" value={title} onChange={handleChange} />
          </div>
          <div className="fileUploadWrap coverImgWrap">
            <label>Cover Img:</label>
            <div>
              <img src={course.imgUrl} />
              <div className="fileUploadWrapper">
                <input type="file" onChange={(e) => setValues({ ...values, img: e.target.files[0] })}></input>
                <span id="absoluteHelperText">{course.imgPath.split(' - ')[1]}</span>
              </div>
            </div>
          </div>
          <div>
            <label>Creator Name:</label>
            <input type="text" id="exampleInputEmail1" name="creator" value={creator} onChange={handleChange} />
          </div>
          <div>
            <label># of Lessons:</label>

            <p>{course.lessons?.length}</p>
          </div>
          <div>
            <label>Total Time:</label>
            {/* <p>5 hours, 18 mins</p> */}
            <p>{duration}</p>
          </div>
          <div>
            <label>Status</label>
            <Select
              fullWidth
              classNamePrefix={'statusDropdown'}
              className="statusDropdown"
              placeholder="Select Course Status"
              options={options}
              onChange={onChangeStatus}
            />
          </div>
          <div>
            <label>Last Edited:</label>
            <p>
              {course.updatedAt ? course.updatedAt.toDate().toGMTString() : course.createdAt.toDate().toGMTString()}
            </p>
          </div>
          <div>
            <label>Course Notes:</label>
            <textarea rows={5} placeholder="Course Notes:" name="notes" value={notes} onChange={handleChange} />
          </div>
          <button type="button" id="updateCourseBtn" onClick={() => updateCourse(course)}>
            Update Course
          </button>
        </form>

        <hr className="newLB" />
        {course.lessons.length
          ? course.lessons.map((lesson, i) => (
              <UpdateLesson key={i} lesson={lesson} course={course} i={i} toggleModal={toggleModal} />
            ))
          : null}

        <div className="createNewLesson">
          <button id="createNewLessonBtn" onClick={() => setAddLesson(!addLesson)}>
            Create New Lesson
          </button>
        </div>
        {addLesson && <AddLesson course={course} toggleModal={toggleModal} />}
        <a onClick={toggleModal} className="closeBtnModal">
          {' '}
          <svg xmlns="http://www.w3.org/2000/svg" width="17.688" height="17.688" viewBox="0 0 17.688 17.688">
            <path
              id="close-icon"
              d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
              transform="translate(-7.5 -7.5)"
              fill="#000"
            ></path>
          </svg>
        </a>
      </Modal>
      <Modal isOpen={idxTwo === i} onRequestClose={toggleModalTwo} contentLabel="Modal">
        <form className="modalEditWrap">
          <h3> Delete Course? </h3>
          <p className="warningText">Warning, this action cannot be undone!</p>
          {/* These have values, as we're editing the user..., thus onChange save these values. */}
          <div className="buttnWrap">
            <button id="cancelReset" onClick={toggleModalTwo}>
              Cancel
            </button>
            <button id="deleteUser" onClick={() => deleteCourse(course)} type="button">
              Delete Course
            </button>
          </div>
        </form>
        <a onClick={toggleModalTwo} className="closeBtnModal">
          {' '}
          <svg xmlns="http://www.w3.org/2000/svg" width="17.688" height="17.688" viewBox="0 0 17.688 17.688">
            <path
              id="close-icon"
              d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
              transform="translate(-7.5 -7.5)"
              fill="#000"
            ></path>
          </svg>
        </a>
      </Modal>
      {/* // Preview Course Modal  */}
      <Modal isOpen={idxThree === i} onRequestClose={toggleModalThree} contentLabel="Modal">
        {/* https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(course.lessons[0]?.lessonFileUrl */}
        {course.lessons.length
          ? course.lessons.map((el, i) => (
              <div key={i} style={{ display: previewIdx === i ? 'block' : 'none' }}>
                <iframe
                  src={`https://docs.google.com/viewer?url=${encodeURIComponent(el.lessonFileUrl)}&embedded=true`}
                  width="100%"
                  height="600px"
                  frameBorder="0"
                  title="slides"
                ></iframe>
                <h3 className="courseIframeTitle">
                  <strong> {el.lessonTitle} </strong> — <small>{course.notes}</small>{' '}
                </h3>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <button
                    id="cancelReset"
                    disabled={previewIdx === 0}
                    onClick={() => previewIdx !== 0 && setPreviewIdx(previewIdx - 1)}
                    style={{ marginRight: '10px' }}
                  >
                    Prev
                  </button>
                  <button
                    id="cancelReset"
                    disabled={course.lessons?.length - 1 === previewIdx}
                    onClick={() => course.lessons?.length - 1 !== previewIdx && setPreviewIdx(previewIdx + 1)}
                  >
                    Next
                  </button>
                </div>
              </div>
            ))
          : null}
        <a onClick={toggleModalThree} className="closeBtnModal">
          {' '}
          <svg xmlns="http://www.w3.org/2000/svg" width="17.688" height="17.688" viewBox="0 0 17.688 17.688">
            <path
              id="close-icon"
              d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
              transform="translate(-7.5 -7.5)"
              fill="#000"
            ></path>
          </svg>
        </a>
      </Modal>
    </AccordionItem>
  );
};

export default Course;
