import Row from '@paljs/ui/Row';
import Col from '@paljs/ui/Col';
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import SEO from '../SEO';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../../utils/fb';
import { convertFromSeconds } from '../../utils/handleMoment';

const Instructor = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    // Update the document title using the browser API
    document.querySelector('body')?.classList.add('instructor');

    const fetchDetails = async () => {
      let user = await getDoc(doc(db, 'users', auth.currentUser?.uid));
      let userData = user.data().courses;
      let courses = [];
      for (let i = 0; i < userData.length; i++) {
        onSnapshot(doc(db, 'courses', userData[i].value), (doc) => {
          setCourses(courses.concat(doc.data()));
        });
      }
    };
    fetchDetails();
  }, []);

  const transformTime = (course) => {
    let data = convertFromSeconds(course.duration);
    let a = data.split(':');
    if (a[0] < '01') {
      return a[1] + ' min';
    } else if (a[1] < '01') {
      return a[0] + ' hr ';
    } else {
      return a[0] + ' hr ' + a[1] + ' min';
    }
  };

  return (
    <>
      <SEO title="Accordions" />
      <Row className="userTabs">
        <Col breakPoint={{ xs: 12, lg: 12 }}>
          <h2 style={{ textAlign: 'center' }}>Available Courses</h2>
          <div className="courseCardRow">
            {courses.map((course) => (
              <div className="courseCardWrapper" key={course.id}>
                <img src={course.imgUrl} />
                <h4>{course.title}</h4>
                <p>
                  Lessons: {course.lessons.length}
                  <br />
                  Total Time: {transformTime(course)}
                </p>
                <Link to={`/app/instructor/course/${course.slug}`} state={{ id: course.id }}>
                  <button id="editUserBtn">Start Course</button>
                </Link>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Instructor;
