import React, { useState, useRef } from 'react';
import { AccordionItem } from '@paljs/ui/Accordion';
import Modal from 'react-modal';
import Select from '@paljs/ui/Select';
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../utils/fb';
import { Toastr, ToastrRef } from '@paljs/ui/Toastr';
import { hhMMss } from '../utils/handleMoment';

const options = [
  { value: 'Active', label: 'Active' },
  { value: 'Deactive', label: 'Deactivated' },
];

const userOptions = [
  { value: 'Admin', label: 'Admin' },
  { value: 'Instructor', label: 'Instructor' },
];

const User = (props: any) => {
  const { user, i } = props;
  const [idxOne, setIdxOne] = useState(-1);
  const [idxTwo, setIdxTwo] = useState(-1);
  const [courseOptions, setCourseOptions] = useState([]);

  const [values, setValues] = useState({
    name: user.name,
    email: user.email,
    company: user.company,
    position: user.position,
    notes: user.notes,
    status: user.status,
    role: user.role,
    courses: user.courses,
  });
  const [newPass, setNewPass] = useState('******');
  const handleChange = (e: any) => setValues({ ...values, [e.target.name]: e.target.value });

  const onChangeStatus = (selectedOption: any) => {
    setValues({ ...values, status: selectedOption });
  };

  const onChangeAccType = (selectedOption: any) => {
    setValues({ ...values, role: selectedOption });
  };

  const onChangeAssignCourse = async (selectedOption: any) => {
    let findCourse = user.courses.find((course) => course.value === selectedOption.value);
    if (!findCourse) {
      const snap = await getDoc(doc(db, 'courses', selectedOption.value));

      let course = {
        label: selectedOption.label,
        value: selectedOption.value,
        lessons: snap.data().lessons.map((lesson) => {
          let item = { createdAt: lesson.createdAt, timeSpent: 0 };
          return item;
        }),
      };

      await updateDoc(doc(db, 'users', user.uid), {
        courses: user.courses.concat(course),
      });
      setValues({ ...values, courses: user.courses.concat(course) });
      showToastr('Success', 'Course assigned');
    }
  };

  async function toggleModal() {
    if (idxOne !== i) {
      setIdxOne(i);
      let options = [];
      const q = query(collection(db, 'courses'), where('status.value', '==', 'Publish'));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        options.push({ label: doc.data().title, value: doc.id });
      });
      setCourseOptions(options);
    } else {
      setIdxOne(-1);
    }
    // idxOne !== i ? setIdxOne(i) : setIdxOne(-1);
  }

  const removeAssignedCourse = async (courseId) => {
    const filterCourses = values.courses.filter((course) => course.value !== courseId);
    try {
      await updateDoc(doc(db, 'users', user.uid), {
        courses: filterCourses,
      });
      setValues({ ...values, courses: filterCourses });
      showToastr('Success', 'Course unassigned');
    } catch (err) {
      console.log(err.message);
    }
  };

  function toggleModalTwo() {
    // setIsOpenTwo(!isOpenTwo);
    idxTwo !== i ? setIdxTwo(i) : setIdxTwo(-1);
  }

  const updateUser = async (id: any) => {
    let data = {
      id,
      name: values.name,
      email: values.email,
      company: values.company,
      position: values.position,
      status: values.status,
      role: values.role,
    };
    try {
      await fetch('/.netlify/functions/updateUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      // toggleModal();
      showToastr('Success', 'User updated');
    } catch (err) {
      console.log(err);
    }
  };

  const changePassword = async (id: any) => {
    let data = {
      id,
      newPass,
    };
    try {
      await fetch('/.netlify/functions/resetPassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      toggleModal();
    } catch (err) {
      console.log(err);
    }
  };
  const deleteUserAcc = async (id: any) => {
    try {
      await fetch('/.netlify/functions/deleteUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(id),
      });
      toggleModalTwo();
    } catch (err) {
      console.log(err);
    }
  };

  const deactivateUser = async (id) => {
    await updateDoc(doc(db, 'users', id), {
      status: { value: 'Deactive', label: 'Deactivated' },
    });
  };

  const reactivateUser = async (id) => {
    await updateDoc(doc(db, 'users', id), {
      status: { value: 'Active', label: 'Active' },
    });
  };
  const displayCourses = () => {
    let item = user.courses.map((course) => course.label);
    item = item.toString();
    item = item.split(',');
    item = item.join(', ');
    return user.courses.length ? item : 'none';
  };

  const toastrRef = useRef<ToastrRef>(null);

  const showToastr = (title, message) => {
    toastrRef.current?.add(message, title);
  };

  return (
    <>
      <Toastr ref={toastrRef} />
      <AccordionItem
        uniqueKey={user.uid}
        title={
          <div className="userNameWrap">
            <h6>{user.name}</h6>
            {user.status?.value === 'Active' ? <p>Active</p> : <p className="deactivated">Deactivated</p>}
          </div>
        }
      >
        <h6> User Details: </h6>
        <p>
          <strong>Name:</strong> {user.name} <br />
          <strong>Email:</strong> {user.email} <br />
          <strong>Company: </strong> {user.company} <br />
          <strong>Position:</strong> {user.position} <br />
          <strong>Notes:</strong> {user.notes} <br />
          <strong>Assigned Courses: {displayCourses()}</strong>
        </p>
        <h6> App Usage: </h6>
        <p>
          <strong> Registration Date: </strong> {new Date(user.createdAt).toGMTString()} <br />
          <strong> Last Login: </strong> {user.lastLogin?.toDate().toDateString()} <br />
          <strong> Total Logins: </strong> {user.totalLogin} <br />
          <strong> Time Spent on App </strong> {hhMMss(user.timeSpent / 1000)} <br />
        </p>
        <div className="userButtonWrapper">
          <button id="editUserBtn" onClick={toggleModal}>
            Edit User
          </button>
          {user.status.value === 'Active' ? (
            <button id="deleteUserBtn" onClick={() => deactivateUser(user.uid)}>
              Deactivate User
            </button>
          ) : (
            <>
              <button id="activateUserBtn" onClick={() => reactivateUser(user.uid)}>
                Reactivate User
              </button>
              <button id="deleteUserBtn" onClick={toggleModalTwo}>
                Delete User
              </button>
            </>
          )}
        </div>

        <Modal isOpen={idxOne === i} onRequestClose={toggleModal} contentLabel="Modal">
          <h3> Edit User </h3>
          {/* These have values, as we're editing the user..., thus onChange save these values. */}
          <form className="modalEditWrap">
            <div>
              <label>Name:</label>
              <input type="text" id="exampleInputEmail1" name="name" value={values.name} onChange={handleChange} />
            </div>
            <div>
              <label>Email:</label>
              <input type="email" id="exampleInputEmail1" name="email" value={values.email} onChange={handleChange} />
            </div>
            <div>
              <label>Company:</label>
              <input
                type="text"
                id="exampleInputEmail1"
                name="company"
                value={values.company}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Position:</label>
              <input
                type="text"
                id="exampleInputEmail1"
                name="position"
                value={values.position}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Notes:</label>
              <textarea
                rows={5}
                placeholder="Course Notes:"
                name="notes"
                value={values.notes}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Status</label>

              <Select
                fullWidth
                placeholder="Select User Status"
                options={options}
                className="statusDropdown"
                onChange={onChangeStatus}
                value={values.status}
              />
            </div>
            <div>
              <label>Account Type</label>

              <Select
                fullWidth
                placeholder="Select Account Type"
                options={userOptions}
                className="statusDropdown"
                onChange={onChangeAccType}
                value={values.role}
              />
            </div>

            <div className={values.courses.length ? 'active' : 'empty'}>
              <label></label>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                {values.courses.length
                  ? values.courses.map((course) => (
                      <div key={course.value} style={{ color: 'black' }} className="assignedCourseLabel">
                        {course.label}
                        <span onClick={() => removeAssignedCourse(course.value)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.688"
                            height="17.688"
                            viewBox="0 0 17.688 17.688"
                          >
                            <path
                              id="close-icon"
                              d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                              transform="translate(-7.5 -7.5)"
                              fill="#000"
                            ></path>
                          </svg>
                        </span>
                        <br />
                      </div>
                    ))
                  : null}
              </div>
            </div>
            <div>
              <label>Assigned Courses</label>

              <Select
                fullWidth
                placeholder="Assign Additional Course"
                options={courseOptions}
                className="statusDropdown"
                onChange={onChangeAssignCourse}
                // value={values.role}
              />
            </div>

            <button id="updateUser" type="button" onClick={() => updateUser(user.uid)}>
              Update User
            </button>

            <>
              <h3> Reset Password </h3>
              {/* These have values, as we're editing the user..., thus onChange save these values. */}
              <div>
                <label>Password:</label>
                <input
                  type="password"
                  id="exampleInputEmail1"
                  value={newPass}
                  onChange={(e) => setNewPass(e.target.value)}
                />
              </div>
              <button id="updateUser" onClick={() => changePassword(user.uid)} type="button">
                Update Password
              </button>
            </>
          </form>
          <a onClick={toggleModal} className="closeBtnModal">
            {' '}
            <svg xmlns="http://www.w3.org/2000/svg" width="17.688" height="17.688" viewBox="0 0 17.688 17.688">
              <path
                id="close-icon"
                d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                transform="translate(-7.5 -7.5)"
                fill="#000"
              ></path>
            </svg>
          </a>
        </Modal>

        <Modal isOpen={idxTwo === i} onRequestClose={toggleModalTwo} contentLabel="Modal">
          <form className="modalEditWrap">
            <h3> Delete User? </h3>
            <p className="warningText">Warning, this action cannot be undone!</p>
            {/* These have values, as we're editing the user..., thus onChange save these values. */}
            <div className="buttnWrap">
              <button id="cancelReset">Cancel</button>
              <button id="deleteUser" onClick={() => deleteUserAcc(user.uid)} type="button">
                Delete User
              </button>
            </div>
          </form>
          <a onClick={toggleModalTwo} className="closeBtnModal">
            {' '}
            <svg xmlns="http://www.w3.org/2000/svg" width="17.688" height="17.688" viewBox="0 0 17.688 17.688">
              <path
                id="close-icon"
                d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                transform="translate(-7.5 -7.5)"
                fill="#000"
              ></path>
            </svg>
          </a>
        </Modal>
      </AccordionItem>
    </>
  );
};

export default User;
