import Row from '@paljs/ui/Row';
import Col from '@paljs/ui/Col';
import React, { useState, useEffect } from 'react';
import SEO from '../../SEO';
import { isInstructor } from '../../../utils/isInstructor';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../../utils/fb';

const Accordions = () => {
  useEffect(() => {
    // Update the document title using the browser API
    document.querySelector('body')?.classList.add('instructor');
  });
  const [courses, setCourses] = useState([]);
  useEffect(() => {
    isInstructor();
    let q = query(collection(db, 'courses'), orderBy('createdAt', 'desc'), where('status.value', '==', 'Publish'));
    let unsub = onSnapshot(q, (querySnapshot) => {
      let courses = [];
      querySnapshot.forEach((doc) => {
        courses.push({ ...doc.data(), id: doc.id });
      });
      setCourses(courses?.filter((el) => el.lessons.reduce((acc, c) => c.timeSpent >= c.lessonDuration)));
      // console.log(courses)
      // setCourses(courses);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <SEO title="Accordions" />
      <Row className="userTabs">
        <Col breakPoint={{ xs: 12, lg: 12 }}>
          <div className="courseCardRow">
            {courses.length
              ? courses.map((course) => (
                  <div className="courseCardWrapper" key={course.id}>
                    <img src={course.imgUrl} />
                    <h4>{course.title}</h4>
                    {/* <p>Completed on June 12, 2022 at 05:10:32 PM</p>
              <p>Time To Complete: 40 mins 23 seconds</p> */}
                  </div>
                ))
              : null}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Accordions;
