import { Accordion, AccordionItem, AccordionRefObject } from '@paljs/ui/Accordion';
import Row from '@paljs/ui/Row';
import Col from '@paljs/ui/Col';
import React, { useRef, useEffect, useState } from 'react';
import SEO from '../SEO';
import { collection, deleteDoc, doc, onSnapshot, query } from 'firebase/firestore';
import { db } from '../../utils/fb';
import { isInstructor } from '../../utils/isInstructor';

const Accordions = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    isInstructor();
    const q = query(collection(db, 'requests'));

    const unsub = onSnapshot(q, (querySnapshot) => {
      let users: any = [];
      querySnapshot.forEach((doc) => {
        users.push({ ...doc.data(), id: doc.id });
      });
      setUsers(users);
    });
    return () => unsub();
  }, []);

  const accordionRef = useRef<AccordionRefObject>(null);
  const style = { marginBottom: '1.5rem' };

  const acceptUser = async (user: any) => {
    let data = {
      name: user.name,
      email: user.email,
      password: user.password,
      company: user.company,
      position: user.position,
      role: user.role,
      notes: user.notes,
    };
    try {
      await fetch('/.netlify/functions/createUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      await deleteDoc(doc(db, 'requests', user.id));
    } catch (err) {
      setErr(err.message);
    }
  };
  const declineRequest = async (user: any) => {
    await deleteDoc(doc(db, 'requests', user.id));
  };
  return (
    <>
      <SEO title="Accordions" />
      <Row className="userTabs acceptUser">
        <Col breakPoint={{ xs: 12, lg: 12 }}>
          {users.length > 0 ? (
            <div>
              <h2 style={{ textAlign: 'center' }}>Pending Users</h2>
              <p className="centerMe">
                These users have requested an account via our login page. Please approve or decline based on your
                discretion.
              </p>
            </div>
          ) : (
            <h2 style={{ textAlign: 'center' }}>No Pending Users</h2>
          )}
          <Accordion multi ref={accordionRef}>
            {users.length > 0 ? (
              users.map((user) => (
                <AccordionItem
                  uniqueKey={user.id}
                  title={
                    <div className="userNameWrap">
                      <h6>{user.name}</h6>
                      <p>Pending</p>
                    </div>
                  }
                >
                  <h6> User Details: </h6>
                  <p>
                    <strong>Name:</strong> {user.name} <br />
                    <strong>Email:</strong> {user.email} <br />
                    <strong>Company: </strong> {user.company} <br />
                    <strong>Position:</strong> {user.position} <br />
                    {/* <strong> Registration Date: </strong> May, 20, 2022 18:45:34 EST <br /> */}
                  </p>
                  <div className="userButtonWrapper">
                    <button id="acceptUserBtn" onClick={() => acceptUser(user)}>
                      Accept User
                    </button>
                    <button id="deleteUserBtn" onClick={() => declineRequest(user)}>
                      Decline Request
                    </button>
                  </div>
                </AccordionItem>
              ))
            ) : (
              <p className="centerMe">
                Users who request to ceate an account via login page will appear here for approval.
              </p>
            )}
          </Accordion>
        </Col>
      </Row>
    </>
  );
};

export default Accordions;
