import Modal from 'react-modal';
import React, { useState, useRef, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../utils/fb';
import { convertFromSeconds, hhMMss } from '../utils/handleMoment';

const Lesson = ({ lesson, i, course }) => {
  const [timeSpent, setTimeSpent] = useState();
  let timer = useRef(null);

  const [lessons, setLessons] = useState([]);
  const [courses, setCourses] = useState([]);

  const [idx, setIdx] = useState(-1);

  useEffect(() => {
    const getDetails = async () => {
      const snap = await getDoc(doc(db, 'users', auth.currentUser?.uid));

      setCourses(snap.data().courses);

      let thisCourse = snap.data().courses.filter((item) => item.value === course.id);
      setLessons(thisCourse[0].lessons);
    };
    getDetails();
  }, []);

  const handleBlur = () =>
    i !== 0 && course.lessons[i - 1].lessonDuration > lessons[i - 1]?.timeSpent / 1000 ? 'notStarted' : '';

  function openModal() {
    setIdx(i);
    calculateLessonTime();
  }
  async function closeModal() {
    clearInterval(timer.current);

    courses[idx].lessons[i] = {
      createdAt: lesson.createdAt,
      timeSpent: timeSpent,
    };

    await updateDoc(doc(db, 'users', auth.currentUser?.uid), {
      courses: courses,
    });

    setIdx(-1);
  }

  const transformTime = () => {
    let data = convertFromSeconds(lesson.lessonDuration);
    let a = data.split(':');
    if (a[0] < '01') {
      return a[1] + ' min';
    } else if (a[1] < '01') {
      return a[0] + ' hr ';
    } else {
      return a[0] + ' hr ' + a[1] + ' min';
    }
  };

  const calculateLessonTime = () => {
    let timerStart;
    let timeSpentOnSite = getTimeSpentOnSite();

    function getTimeSpentOnSite() {
      timeSpentOnSite = parseInt(localStorage.getItem(`${auth.currentUser?.uid}-${lesson.lessonTitle}`));
      timeSpentOnSite = isNaN(timeSpentOnSite) ? 0 : timeSpentOnSite;
      return timeSpentOnSite;
    }

    function startCounting() {
      timerStart = Date.now();
      timer.current = setInterval(function () {
        timeSpentOnSite = getTimeSpentOnSite() + (Date.now() - timerStart);
        localStorage.setItem(`${auth.currentUser?.uid}-${lesson.lessonTitle}`, timeSpentOnSite);
        setTimeSpent(timeSpentOnSite);
        timerStart = parseInt(Date.now());
        // Convert to seconds
        console.log(parseInt(timeSpentOnSite / 1000));
      }, 1000);
    }
    startCounting();
  };

  return lessons.length ? (
    <div key={i} className={handleBlur()}>
      <img src={lesson.lessonCoverUrl} />
      <h4>{lesson.lessonTitle}</h4>
      <p>Estimated Time: {transformTime()}</p>
      <button
        id="activateUserBtn"
        onClick={openModal}
        disabled={i !== 0 && course.lessons[i - 1].lessonDuration > lessons[i - 1]?.timeSpent / 1000}
      >
        Start Lesson
      </button>

      <Modal isOpen={idx === i} onRequestClose={closeModal} contentLabel="Modal">
        <iframe
          src={`https://docs.google.com/viewer?url=${encodeURIComponent(lesson.lessonFileUrl)}&embedded=true`}
          width="100%"
          height="600px"
          frameBorder="0"
          title="slides"
        ></iframe>
        <h3 className="courseIframeTitle">
          <strong> {lesson.lessonTitle} </strong> — Updated on{' '}
          {lesson.updatedAt ? lesson.updatedAt.toDate().toGMTString() : lesson.createdAt.toDate().toGMTString()}
        </h3>
        <h4 className="courseIframeTitle">
          Time Spent: {hhMMss(parseInt(localStorage.getItem(`${auth.currentUser?.uid}-${lesson.lessonTitle}`)) / 1000)}
        </h4>
        <a onClick={closeModal} className="closeBtnModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="17.688" height="17.688" viewBox="0 0 17.688 17.688">
            <path
              id="close-icon"
              d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
              transform="translate(-7.5 -7.5)"
              fill="#000"
            ></path>
          </svg>
        </a>
      </Modal>
    </div>
  ) : null;
};

export default Lesson;
