import moment from 'moment';

// conver hh:mm into seconds format
export const convertToSeconds = (time) => {
  const data = moment.duration(time).asSeconds();
  return data;
};

// convert seconds into hh:mm format
export const convertFromSeconds = (time) => {
  const data = moment.utc(time * 1000).format('HH:mm');
  return data;
};

// convert seconds into hh:mm:ss format
export const hhMMss = (time) => {
  const data = moment.utc(time * 1000).format('HH:mm:ss');
  return data;
};