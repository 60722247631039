import Select from '@paljs/ui/Select';
import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SEO from '../SEO';
import { isInstructor } from '../../utils/isInstructor';
// import { dropdownIndicatorCSS } from 'react-select/src/components/indicators';
import { Toastr, ToastrRef } from '@paljs/ui/Toastr';
import { Timestamp } from 'firebase/firestore';

const options = [
  { value: 'Instructor', label: 'Instructor' },
  { value: 'Admin', label: 'Admin' },
];

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const InputPage = () => {
  useEffect(() => {
    isInstructor();
  }, []);

  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',
    company: '',
    position: '',
    role: '',
    notes: '',
  });
  const { name, email, password, company, position, role, notes } = values;
  const [nameErr, setNameErr] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [passwordErr, setPasswordErr] = useState('');
  const [companyErr, setCompanyErr] = useState('');
  const [positionErr, setPositionErr] = useState('');
  const [roleErr, setRoleErr] = useState('');
  const [notesErr, setNotesErr] = useState('');
  const [err, setErr] = useState('');

  const handleChange = (e: any) => setValues({ ...values, [e.target.name]: e.target.value });

  const onChangeRole = (selectedOption: any) => {
    setValues({ ...values, role: selectedOption });
  };

  function validateEmail(email: any) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const handleErr = (val: string, setValErr: any, message: string) => {
    if (!val) {
      setValErr(message);
      return;
    }
  };

  const handleClick = async () => {
    setNameErr('');
    setEmailErr('');
    setPasswordErr('');
    setCompanyErr('');
    setPositionErr('');
    setRoleErr('');
    setNotesErr('');
    handleErr(name, setNameErr, 'Name is required');
    handleErr(email, setEmailErr, 'Email is required');
    handleErr(password, setPasswordErr, 'Password is required');
    handleErr(company, setCompanyErr, 'Company is required');
    handleErr(position, setPositionErr, 'Position is required');
    handleErr(role, setRoleErr, 'Role is required');
    handleErr(notes, setNotesErr, 'Notes is required');
    if (!validateEmail(email)) {
      setEmailErr('Please provide a valid email');
      return;
    }
    let data = {
      name,
      email,
      password,
      company,
      position,
      role,
      notes,
      createdAt: new Date(),
      totalLogin: 0,
      timeSpent: 0,
    };
    try {
      await fetch('/.netlify/functions/createUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      setValues({ name: '', email: '', password: '', company: '', position: '', role: '', notes: '' });
      showToastr('Success', 'User successfully created');      
    } catch (err) {
      setErr(err.message);
    }
  };

  const toastrRef = useRef<ToastrRef>(null);

  const showToastr = (title, message) => {
    toastrRef.current?.add(message, title);
  };

  return (
    <>
      <Toastr ref={toastrRef} />
      <SEO title="Input" />
      <Row className="lsUserForm">
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <h2 style={{ textAlign: 'center' }}>Create New User</h2>
          <Card>
            <header>Fill in the following details. You can always edit this later.</header>
            <CardBody>
              <Input fullWidth size="Medium" className="formInputFieldWrapper">
                <input type="text" placeholder="Full Name" name="name" value={name} onChange={handleChange} />
                {nameErr && <p>{nameErr}</p>}
              </Input>
              <Input fullWidth size="Medium" className="formInputFieldWrapper">
                <input type="text" placeholder="Email address" name="email" value={email} onChange={handleChange} />
                {emailErr && <p>{emailErr}</p>}
              </Input>
              <Input fullWidth size="Medium" className="formInputFieldWrapper">
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                />
                {passwordErr && <p>{passwordErr}</p>}
              </Input>
              <Input fullWidth size="Medium" className="formInputFieldWrapper">
                <input type="text" placeholder="Company" name="company" value={company} onChange={handleChange} />
                {companyErr && <p>{companyErr}</p>}
              </Input>
              <Input fullWidth size="Medium" className="formInputFieldWrapper">
                <input type="text" placeholder="Position" name="position" value={position} onChange={handleChange} />
                {positionErr && <p>{positionErr}</p>}
              </Input>
              <Select
                classNamePrefix={'dropDownGeneral'}
                fullWidth
                placeholder="Select User Type"
                options={options}
                onChange={onChangeRole}
              />
              {roleErr && <p>{roleErr}</p>}
              <Input fullWidth shape="Round" className="formInputFieldWrapper">
                <textarea rows={5} placeholder="Custom Notes:" name="notes" value={notes} onChange={handleChange} />
                {notesErr && <p>{notesErr}</p>}
              </Input>
              <button id="createUserBtn" onClick={handleClick}>
                Create User
              </button>
              {err && <p>{err}</p>}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default InputPage;
