import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from './fb';
import { navigate } from 'gatsby';

export const isInstructor = () => {
  if (auth.currentUser) {
    getDoc(doc(db, 'users', auth?.currentUser?.uid)).then((el) => {
      if (el.data().role.value === 'Instructor') {
        navigate('/app/instructor');
      }
    });
  }
};
