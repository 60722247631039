import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { useState, useRef } from 'react';
import { db, storage } from '../utils/fb';
import { Toastr, ToastrRef } from '@paljs/ui/Toastr';

const UpdateLesson = ({ lesson, course, i, toggleModal }) => {
  const [theLesson, setTheLesson] = useState({
    lessonTitle: lesson.lessonTitle,
    lessonFile: '',
    lessonDuration: lesson.lessonDuration,
    // lessonHrs: '00',
    // lessonMins: '00',
    lessonImg: '',
  });
  const { lessonTitle, lessonFile, lessonDuration, lessonImg } = theLesson;
  const handleLessonChange = (e: any) => setTheLesson({ ...theLesson, [e.target.name]: e.target.value });

  const updateLesson = async () => {
    let lessonFileUrl;
    let lessonFilePath;
    if (lessonFile) {
      await deleteObject(ref(storage, lesson.lessonFilePath));
      const imgRef = ref(storage, `lessons/files/${new Date().getTime()} - ${lessonFile.name}`);
      const snap = await uploadBytes(imgRef, lessonFile);
      const fileUrl = await getDownloadURL(ref(storage, snap.ref.fullPath));
      lessonFileUrl = fileUrl;
      lessonFilePath = snap.ref.fullPath;
    }
    let lessonCoverUrl;
    let lessonCoverPath;
    if (lessonImg) {
      await deleteObject(ref(storage, lesson.lessonCoverPath));
      const imgRef = ref(storage, `lessons/covers/${new Date().getTime()} - ${lessonImg.name}`);
      const snap = await uploadBytes(imgRef, lessonImg);
      const fileUrl = await getDownloadURL(ref(storage, snap.ref.fullPath));
      lessonCoverUrl = fileUrl;
      lessonCoverPath = snap.ref.fullPath;
    }
    course.lessons[i] = {
      lessonTitle,
      lessonFileUrl: lessonFileUrl || lesson.lessonFileUrl,
      lessonFilePath: lessonFilePath || lesson.lessonFilePath,
      lessonDuration,
      lessonCoverUrl: lessonCoverUrl || lesson.lessonCoverUrl,
      lessonCoverPath: lessonCoverPath || lesson.lessonCoverPath,
      updatedAt: Timestamp.fromDate(new Date()),
      createdAt: lesson.createdAt,
      timeSpent: lesson.timeSpent,
    };
    await updateDoc(doc(db, 'courses', course.id), {
      lessons: course.lessons,
      duration: course.lessons.reduce((acc, current) => acc + parseInt(current.lessonDuration), 0),
    });
    showToastr('Success', 'Lesson successfully updated');
    // toggleModal();
  };

  const deleteLesson = async () => {
    // console.log(lesson.lessonFilePath, lesson.lessonCoverPath)
    await deleteObject(ref(storage, lesson.lessonFilePath));

    await deleteObject(ref(storage, lesson.lessonCoverPath));

    await updateDoc(doc(db, 'courses', course.id), {
      lessons: course.lessons.filter((_, idx) => idx !== i),
    });
    showToastr('Success', 'Lesson successfully deleted');
    // toggleModal();
  };

  const toastrRef = useRef<ToastrRef>(null);

  const showToastr = (title, message) => {
    toastrRef.current?.add(message, title);
  };
  return (
    <>
      <Toastr ref={toastrRef} />
      <h3>{lessonTitle}</h3>
      <form className="modalEditWrap">
        <div>
          <label>Name:</label>
          <input
            type="text"
            id="exampleInputEmail1"
            name="lessonTitle"
            value={lessonTitle}
            onChange={handleLessonChange}
          />
        </div>
        <div className="fileUploadWrap">
          <label>File:</label>
          <div className="fileUploadWrapper">
            <input type="file" onChange={(e) => setTheLesson({ ...theLesson, lessonFile: e.target.files[0] })}></input>
            <span>{lesson.lessonFilePath.split(' - ')[1]}</span>
          </div>
        </div>
        <div>
          <label>Time Duration:</label>
          <input
            type="text"
            id="exampleInputEmail1"
            name="lessonDuration"
            value={lessonDuration}
            onChange={handleLessonChange}
          />
        </div>
        {/* <div className="timeDurationTimeSelector">
          <label>Time Duration:</label>
          <input
            type="number"
            id="exampleInputEmail1"
            placeholder="Hours"
            name="lessonHrs"
            value={lessonHrs}
            onChange={handleLessonChange}
            className="firstInputLesson"
          />
          <input
            type="number"
            id="exampleInputEmail1"
            placeholder="Minutes"
            name="lessonMins"
            value={lessonMins}
            onChange={handleLessonChange}
          />
        </div> */}
        <div className="fileUploadWrap">
          <label>Cover Image:</label>
          <div>
            <img src={lesson.lessonCoverUrl} style={{ width: '200px', height: '200px' }} />
            <div className="fileUploadWrapper">
              <input type="file" onChange={(e) => setTheLesson({ ...theLesson, lessonImg: e.target.files[0] })}></input>
              <span>{lesson.lessonCoverPath.split(' - ')[1]}</span>
            </div>
          </div>
        </div>
        <div className="courseLessonTable"></div>
        <div className="userButtonWrapper">
          <button id="updateUser" onClick={updateLesson} type="button">
            Update {lessonTitle}
          </button>
          <button id="deleteUser" onClick={deleteLesson} type="button">
            Delete Lesson
          </button>
        </div>
        <hr className="newLB" />
      </form>
    </>
  );
};

export default UpdateLesson;
