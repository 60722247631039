import { Link } from 'gatsby';
import Row from '@paljs/ui/Row';
import Col from '@paljs/ui/Col';
import React, { useState, useEffect } from 'react';
import SEO from '../../SEO';
import { isInstructor } from '../../../utils/isInstructor';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../../utils/fb';

const Accordions = () => {
  useEffect(() => {
    // Update the document title using the browser API
    document.querySelector('body')?.classList.add('instructor');
  });

  const [courses, setCourses] = useState([]);

  useEffect(() => {
    isInstructor();
    let q = query(collection(db, 'courses'), orderBy('createdAt', 'desc'), where('status.value', '==', 'Publish'));
    let unsub = onSnapshot(q, (querySnapshot) => {
      let courses = [];
      querySnapshot.forEach((doc) => {
        courses.push({ ...doc.data(), id: doc.id });
      });
      courses = courses?.filter((c) => c.lessons.find((lesson) => lesson.timeSpent < lesson.lessonDuration));
      setCourses(courses);
    });
    return () => unsub();
  }, []);
  return (
    <>
      <SEO title="Accordions" />
      <Row className="userTabs openCourses">
        <Col breakPoint={{ xs: 12, lg: 12 }}>
          <div className="courseCardRow">
            {courses.length
              ? courses.map((course, i) => (
                  <div className="courseCardWrapper" key={i}>
                    <img src={course.imgUrl} />
                    <div>
                      <h4>{course.title}</h4>
                      <p>Date Started: {course.createdAt.toDate().toGMTString()}</p>
                      <p>Current Lesson: 3</p>
                      <Link to={`/app/instructor/course/${course.slug}/lessons`}>
                        <button id="activateUserBtn">Start Lesson</button>
                      </Link>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Accordions;
