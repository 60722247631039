import { Accordion, AccordionRefObject } from '@paljs/ui/Accordion';
import Row from '@paljs/ui/Row';
import Col from '@paljs/ui/Col';
import React, { useRef, useState, useEffect } from 'react';
import SEO from '../SEO';

import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../../utils/fb';
import Course from '../Course';
import { isInstructor } from '../../utils/isInstructor';

const Accordions = () => {
  const accordionRef = useRef<AccordionRefObject>(null);

  const [courses, setCourses] = useState([]);

  useEffect(() => {
    isInstructor();
    let q = query(collection(db, 'courses'), orderBy('createdAt', 'desc'));
    let unsub = onSnapshot(q, (querySnapshot) => {
      let courses = [];
      querySnapshot.forEach((doc) => {
        courses.push({ ...doc.data(), id: doc.id });
      });
      setCourses(courses);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <SEO title="Accordions" />
      <Row className="userTabs">
        <Col breakPoint={{ xs: 12, lg: 12 }}>
          <h2 style={{ textAlign: 'center' }}>Courses</h2>
          <Accordion multi ref={accordionRef}>
            {courses.length ? courses.map((course, i) => <Course key={course.id} course={course} i={i} />) : null}
          </Accordion>
        </Col>
      </Row>
    </>
  );
};

export default Accordions;
